<template>
	<div class="comment" :data-post-id="post?.id || postId">
		<div class="flex comments-content">
			<router-link v-if="showAvatar" :to="consumerProfileLink" class="comment-avatar">
				<avatar-circle :image-path="avatar" />
			</router-link>
			<div class="w-100">
				<span @click="onClickNickname" class="comment-nickname">
					{{ nickname }}
					<badge-fan v-if="showFanBadge" />
					<badge-following v-else-if="showFollowerBadge" />
					<badge-bank-card v-if="showBankCardBadge" />
				</span>
				<text-with-hashtags-and-mentioned
					class="comment-text"
					:text="text"
					:preview-letters-count="previewLettersCount"
					:mentioned="mentioned"
				/>
				<div class="release" v-if="createdAt">{{ createdAt }}</div>
			</div>
		</div>
		<slot name="append">
			<div v-if="likedAtText" class="comment-like" v-lazy.background="likedAvatar" />
		</slot>
	</div>
</template>

<script>
import AvatarCircle from '@/components/AvatarCircle.vue'
import TextWithHashtagsAndMentioned from '@/components/TextWithHashtagsAndMentioned.vue'
import BadgeFan from '@/components/BadgeFan.vue'
import BadgeFollowing from '@/components/BadgeFollowing.vue'
import BadgeBankCard from '@/components/BadgeBankCard.vue'
import { mapState } from 'pinia'
import { useProfileStore } from '@/stores/profile'

export default {
	name: 'CommentComponent',
	components: {
		BadgeBankCard,
		BadgeFollowing,
		BadgeFan,
		AvatarCircle,
		TextWithHashtagsAndMentioned
	},
	props: {
		avatar: { type: String, default: '' },
		authorId: { type: Number, default: 0 },
		nickname: { type: String, default: '' },
		createdAt: { type: String, default: '' },
		text: { type: String, default: '' },
		likedAvatar: { type: String, default: '' },
		likedAtText: { type: String, default: '' },
		previewLettersCount: { type: Number, default: 0 },
		onClickNicknameFunction: { type: Function, default: null },
		mentioned: {
			type: Object,
			default: () => {}
		},
		post: {
			type: Object,
			default: () => {}
		},
		postId: {
			type: Number,
			default: 0
		}, // todo привести к одному виду, где-то передается пост, где-то нет
		hasGirlSubscription: { type: Boolean, default: false },
		consumerHasGirlSubscription: { type: Boolean, default: false },
		hasActingGirlFollowing: { type: Boolean, default: false },
		hasActingGirlSubscription: { type: Boolean, default: false },
		isAdult: { type: Boolean, default: false }
	},
	computed: {
		...mapState(useProfileStore, { currenConsumerId: 'id' }),
		showFanBadge() {
			if (this.$config.allowFollowing) {
				return this.hasActingGirlSubscription
			}
			return this.hasGirlSubscription || this.consumerHasGirlSubscription
		},
		showFollowerBadge() {
			return this.$config.allowFollowing && this.hasActingGirlFollowing && !this.showFanBadge
		},
		showBankCardBadge() {
			return this.$config.allowFollowing && !this.showFanBadge && this.isAdult && this.isGirl
		},
		isGirl() {
			return this.$auth.check('girl')
		},
		consumerProfileLink() {
			const routeName = this.$route.name
			if (routeName === 'author-reactions-id' || routeName === 'consumer-reactions-id') {
				return { name: 'consumer-profile-id', params: { id: this.authorId } }
			}
			return { name: 'consumer-profile', params: { nickname: this.nickname } }
		},
		showAvatar() {
			return this.authorId && this.avatar
		}
	},
	methods: {
		onClickNickname() {
			if (this.onClickNicknameFunction) {
				this.onClickNicknameFunction(this.nickname)
				return
			}
			this.$router.push(this.consumerProfileLink)
		}
	}
}
</script>

<style lang="scss">
.comment-like {
	width: 20px;
	height: 20px;
	min-height: 20px;
	min-width: 20px;
	border-radius: 100%;
	background-size: 100%;
	margin-left: 8px;
	background-position: center;
	background-repeat: no-repeat;
	position: relative;
	@media (min-width: $screen-desktop) {
		width: 30px;
		height: 30px;
		min-height: 30px;
		min-width: 30px;
		&::after {
			width: 15px;
			height: 15px;
			background-image: url('@/assets/images/icons/heart-solid.svg');
		}
	}
}

.comment-like::after {
	content: '';
	display: block;
	width: 10px;
	height: 10px;
	background-image: url('@/assets/images/icons/heart-active.png');
	background-size: 100%;
	background-repeat: no-repeat;
	background-position: center;
	position: absolute;
	bottom: -3px;
	right: -3px;
	@media (min-width: $screen-desktop) {
		background-image: url('@/assets/images/icons/heart-solid.svg');
		width: 15px;
		height: 15px;
	}
}

.comment {
	&-avatar {
		margin-right: 10px;
	}

	.avatar-circle {
		width: 30px;
		height: 30px;
		min-width: 30px;
		min-height: 30px;
		@media (min-width: $screen-desktop) {
			width: 40px;
			height: 40px;
			min-width: 40px;
			min-height: 40px;
		}
	}
}
</style>

<style lang="scss" scoped>
.comments-content {
	& .comment-avatar {
		cursor: pointer;
		transition: $transition-opacity;
	}

	& .comment-nickname {
		cursor: pointer;
		transition: $transition-color;
		display: flex;
		gap: 5px;
		align-items: center;
	}

	&:hover {
		& .comment-avatar {
			opacity: 0.7;
		}

		& .comment-nickname {
			color: $color-white-hover;
		}
	}
}
</style>
